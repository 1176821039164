exports.productsInformations = 'Products Informations';
exports.products = [
  {
    productName: 'vegetables',
    description: ['Tomato', 'Pepper', 'Cucumber'],
    explanation: 'Bu elma antalyada yetişti ve organiktir. Sulu ve ekşidir.',
    src: [
      '../../img/sebzeler/domates.jpg',
      '../../img/sebzeler/ceri_salkim_domates.jpg',
      '../../img/sebzeler/ciko_domates.jpg',
      '../../img/sebzeler/kokteyl_seyit_domates.jpg',
      '../../img/sebzeler/pembe_domates.jpg',
      '../../img/sebzeler/pembe_kokteyl_domates.jpg',
      '../../img/sebzeler/salkim_domates.jpg',
      '../../img/sebzeler/sari_kokteyl_domates.jpg',
      '../../img/sebzeler/zebra_domates.jpg',
      '../../img/sebzeler/sili_biber.jpg',
      '../../img/sebzeler/sivri_biber.jpg',
      '../../img/sebzeler/dolmalik_biber.jpg',
      '../../img/sebzeler/kalifornia_biber.jpg',
      '../../img/sebzeler/kapya_biber.jpg',
      '../../img/sebzeler/macar_dolmalik_biber.jpg',
      '../../img/sebzeler/macar_sivri_biber.jpg',
      '../../img/sebzeler/carliston_biber.jpg',
      '../../img/sebzeler/patlican.jpg',
      '../../img/sebzeler/topak_patlican.jpg',
      '../../img/sebzeler/brokoli.jpg',
      '../../img/sebzeler/dikenli_hiyar.jpg',
      '../../img/sebzeler/hiyar.jpg',
      '../../img/sebzeler/havuc.jpg',
      '../../img/sebzeler/kabak.jpg',
      '../../img/sebzeler/karnibahar.jpg',
      '../../img/sebzeler/lahana.jpg',
    ],
    names: [
      'Tomato',
      'Cherry Tomato',
      'Ciko Tomato',
      'Cocktail Tomato',
      'Pink Tomato',
      'Pink Cocktail Tomato',
      'Truss Tomato',
      'Yellow Cocktail Tomato',
      'Zebra Tomato',
      'Chili Pepper',
      'Green Biber',
      'Bell Biber',
      'California Biber',
      'Capia Biber',
      'Hungarian Bell Biber',
      'Hungarian Green Biber',
      'Banana Chili',
      'Eggplant',
      'Bell Eggplant',
      'Broccoli',
      'Prickly Cucumber',
      'Cucumber',
      'Carrot',
      'Pumpkin',
      'Cauliflower',
      'Cabbage',
    ],
  },
  {
    productName: 'fruits',
    description: ['Apple', 'Pear', 'Strawberry'],
    explanation: 'Bu kiraz antalyada yetişti ve organiktir. Sulu ve ekşidir.',
    src: [
      '../../img/meyveler/armut.jpg',
      '../../img/meyveler/ayva.jpg',
      '../../img/meyveler/greyfurt.jpg',
      '../../img/meyveler/incir.jpg',
      '../../img/meyveler/karpuz.jpg',
      '../../img/meyveler/kavun.jpg',
      '../../img/meyveler/kayisi.jpg',
      '../../img/meyveler/limon.jpg',
      '../../img/meyveler/mandarin.jpg',
      '../../img/meyveler/nar.jpg',
      '../../img/meyveler/nektarin.jpg',
      '../../img/meyveler/seftali.jpg',
      '../../img/meyveler/portakal.jpg',
      '../../img/meyveler/uzum.jpg',
      '../../img/meyveler/siyah_erik.jpg',
      '../../img/meyveler/yesil_erik.jpg',
    ],
    names: [
      'Pear',
      'Quince',
      'Grapefruit',
      'Fig',
      'Watermelon',
      'Melon',
      'Apricot',
      'Lemon',
      'Mandarin',
      'Pomegranate',
      'Nectarine',
      'Peach',
      'Orange',
      'Grape',
      'Black Plum',
      'Greengage',
    ],
  },
  {
    productName: 'greenhouses',
    description: ['Greenhouses', 'Seedling Plants', 'Hydroponic Greenhouses'],
    explanation: 'Bu kiraz antalyada yetişti ve organiktir. Sulu ve ekşidir.',
    src: [
      '../../img/1-fideTesisi1.jpg',
      '../../img/2-topraksızTarım1.jpg',
      '../../img/3-hidroponikSera1.jpg',
    ],
    names: ['Greenhouses', 'Seedling Plants', 'Hydroponic Greenhouses'],
  },
];
